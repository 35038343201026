import React from 'react';
import "./HomeBannerSlider.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider1 from "../../assets/home-banner/home-bannner4.webp";
import Slider1Mobile from "../../assets/home-banner/home-banner4_mobile.webp";
import Slider3 from "../../assets/home-banner/home-banner3.webp";
import Slider3Mobile from "../../assets/home-banner/home-banner3_mobile.webp";

const HomeBannerSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <Slider {...settings}>
            <div className='home-slider'>
                <img
                    src={Slider1}
                    srcSet={`${Slider1Mobile} 576w, ${Slider1} 1024w`}
                    sizes="(max-width: 576px) 100vw, 100vw"
                    alt="banner"
                    className='home-banner-slider-img'
                />
            </div>
            <div className='home-slider'>
                <img
                    src={Slider3}
                    srcSet={`${Slider3Mobile} 576w, ${Slider3} 1024w`}
                    sizes="(max-width: 576px) 100vw, 100vw"
                    alt="banner"
                    className='home-banner-slider-img'
                />
            </div>
        </Slider>
    );
};

export default HomeBannerSlider;
