import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import HomeBannerSlider from '../../components/HomeBannerSlider/HomeBannerSlider';
import { useInView } from 'react-intersection-observer';

// const LazyAboutSection = lazy(() => import('../../components/AboutSection/AboutSection'));
import AboutSection from "../../components/AboutSection/AboutSection"
const LazyOurProductsSection = lazy(() => import('../../components/OurProductsSection/OurProductsSection'));
const LazyFinanceSection = lazy(() => import('../../components/FinanceSection/FinanceSection'));
const LazyOurClientsSection = lazy(() => import('../../components/OurClientsSection/OurClientsSection'));
const LazyOurServices = lazy(() => import('../../components/OurServices/OurServices'));
const LazyBlogSection = lazy(() => import('../../components/BlogSection/BlogSection'));
const LazyOurBrandsSection = lazy(() => import('../../components/OurBrandsSection/OurBrandsSection'));
const LazyPpaSection = lazy(() => import('../../components/PpaSection/PpaSection'));
const LazyHomeLocationBanner = lazy(() => import('../../components/HomeLocationBanner/HomeLocationBannner'));

const LazyComponent = ({ Component }) => {
    const { ref, inView } = useInView({ triggerOnce: true });

    return (
        <div ref={ref}>
            {inView && (
                <Suspense fallback={<div>Loading...</div>}>
                    <Component />
                </Suspense>
            )}
        </div>
    );
};

const Home = () => {
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Kesrinandan is a solar panel distributor in India offering rooftop solar solutions, & financing for solar installations, making solar energy accessible. Contact us!"
                />
                <title>Leading Solar Panel Distributor in India | Kesrinandan Ventures</title>
                <meta property="og:url" content="https://kesrinandan.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Leading Solar Panel Distributor in India | Kesrinandan Ventures" />
                <meta property="og:description" content="Kesrinandan is a solar panel distributor in India offering rooftop solar solutions, & financing for solar installations, making solar energy accessible. Contact us!" />
                <meta property="og:image" content="https://kesrinandan.com/static/media/kesrinandan.4348074cbbe19e71283609c0c0969ee2.svg"></meta>
                <link rel="canonical" href={window?.location?.href} />
                <script type="application/ld+json">
                    {`
                         {
                           "@context": "https://schema.org",
                           "@type": "WebSite",
                           "name": "Kesrinandan Ventures LLP",
                           "url": "https://kesrinandan.com/",
                           "potentialAction": {
                             "@type": "SearchAction",
                             "target": "https://kesrinandan.com/?foo={search_term_string}",
                             "query-input": "required name=search_term_string"
                           }
                          }
                   `}
                </script>
            </Helmet>

            <HomeBannerSlider />

            <LazyComponent Component={LazyOurBrandsSection} />
            {/* <LazyComponent Component={LazyAboutSection} /> */}
            <AboutSection/>
            <LazyComponent Component={LazyOurProductsSection} />
            <LazyComponent Component={LazyPpaSection} />
            <LazyComponent Component={LazyFinanceSection} />
            <LazyComponent Component={LazyOurServices} />
            <LazyComponent Component={LazyOurClientsSection} />
            <LazyComponent Component={LazyBlogSection} />
            <LazyComponent Component={LazyHomeLocationBanner} />
        </>
    );
};

export default Home;
