import React from 'react';
import "./Faq.css";
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { FaPlus, FaMinus } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Helmet } from 'react-helmet';
import BigTitle2 from '../../components/BigTitle/BigTitle2';
import { Link } from 'react-router-dom';

const FaqSection = () => {

    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <FaPlus className={` toggle-on`} />
                <FaMinus className={`toggle-off`} />
            </Accordion.Trigger>
        </Accordion.Header>
    ));



    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));


    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Explore Kesrinandan's FAQ section for comprehensive answers to common queries. From company policies to product details, find the information you need quickly."
                />
                <title>Get Answers to Your Questions: Kesrinandan Ventures</title>
                <meta property="og:url" content={window?.location?.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Get Answers to Your Questions: Kesrinandan Ventures" />
                <meta property="og:description" content="Explore Kesrinandan's FAQ section for comprehensive answers to common queries. From company policies to product details, find the information you need quickly." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/kesrinandan.4348074cbbe19e71283609c0c0969ee2.svg"></meta>
                <link rel="canonical" href={window?.location?.href} />
            </Helmet>

            <section className="about-banner faq-banner">
                <div className="custom-text custom-text-about">
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Faq', url: '/faq' }]} />
                    </div>
                </div>
            </section>

            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="FAQ'S" />
                </div>
            </section>

            <section className="faq-section">
                <div className="c-container">
                    <h2 className='description'>If you have any question, we will be very happy to answer it</h2>
                    <div className="faq-accordian-section">
                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                            <Accordion.Item className="AccordionItem" value={`1`}>
                                <AccordionTrigger>What is a PPA in the power sector?</AccordionTrigger>
                                <AccordionContent>A power purchase agreement is a contract between two parties; one of whom is the buyer and the other is often a renewable energy producer in the energy industry. It outlines the terms under which electricity will be bought and sold for a given period. Mostly, these purchasers are power utilities or significant users of power who want an uncertain, unchangeable, and durable power supply while the sellers are renewable energy developers. PPA solar promotes investments in renewables by making incomes predictable, encouraging sustainable practices, and thus contributing to greener forms of energy. Those <Link to={"/ppa"} target='_blank'>power purchase agreements</Link> enable the enlargement of renewable capacities within this sector.</AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`2`}>
                                <AccordionTrigger>What are the benefits of a solar panel?</AccordionTrigger>
                                <AccordionContent>
                                    Here are some of the important benefits of a solar panel:
                                    <ul>
                                        <li><BsDot /> <p><span className='l-p'>Environmental Impact:</span> Reducing our dependence on fossil fuels will reduce the carbon footprint and help to combat climate change.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Cost Savings:</span> These eventually lead to lower electric bills, resulting in long-term economic benefits</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Low Maintenance:</span> Solar panels are cheap and efficient with little maintenance requirements.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Grid Resilience:</span> They also decentralize power generation thereby enhancing the overall stability of the network.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Job Creation:</span> This results in jobs in the renewable energy sector through the installation and maintenance of solar panels.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Energy Independence:</span> It therefore reduces reliance on foreign sources of fuel thereby enhancing national energy security</p></li>
                                    </ul>
                                </AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`3`}>
                                <AccordionTrigger>What are the 5 uses of solar energy?</AccordionTrigger>
                                <AccordionContent>
                                    Solar energy has many practical uses, such as:
                                    <ul>
                                        <li><BsDot /> <p><span className='l-p'>Produce Electricity:</span> Solar panels convert sunlight into electric power; this provides a source of clean and renewable energy.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Warm Water:</span> Solar water heaters use the sun’s rays to warm up water for use in residential and industrial areas, reducing dependence on traditional heating systems.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Low Maintenance:</span> Solar panels are cheap and efficient with little maintenance requirements.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Space Heating/Cooling:</span> Buildings designed with passive solar features make better utilization of sunshine during cold months while at the same time reducing exposure during hot months thus improving energy efficiency.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Sun Cooking:</span> Sunlight cooking is achieved through using solar cookers which encourage eco-friendly cooking which is also an energy saver method.</p></li>
                                        <li><BsDot /> <p><span className='l-p'>Off-Grid Power:</span> In areas where electricity has not reached <Link to={"/product/solar-panels"} target='_blank'> rooftop solar solutions</Link> can be used hence increasing access to it and sustainability.</p></li>
                                    </ul>
                                </AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`4`}>
                                <AccordionTrigger>What are the objectives of a solar inverter?</AccordionTrigger>
                                <AccordionContent>Solar inverters change the direct current (DC) generated by solar panels to alternating current (AC) that can be used. In addition, they act as maximum power point trackers on the arrays thus improving power harvest. Also, solar inverters & Panasonic inverters have a grid tie feature that enables them to either export or import power from the main grid. These systems include monitoring and protective aids so that all components work well together, problem areas will be revealed and it will satisfy standard supply <Link to={"/product/inverters"} target='_blank'>inverter system</Link> requirements. Lastly, these are meant to exploit energy sources at optimum levels, encourage sustainable development in environmental conservation, and fully maximize present technologies such as photovoltaic cells into any existing electrical grid.</AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`5`}>
                                <AccordionTrigger>What is the principle of solar inverter? </AccordionTrigger>
                                <AccordionContent>Solar inverters, which are based on the principle of direct current (DC), convert the direct current supplied by solar panels to an alternating current (AC) that can be used in households or fed into the electrical grid. This process requires the application of semiconductors and transformers among other electronic elements to transform DC power into AC at a specific voltage and frequency. To enhance energy production, modern inverters utilize Maximum Power Point Tracking (MPPT) to match the electrical operational point of solar arrays with their highest available sunshine. It is this transformation that makes existing electrical systems compatible with and easily able to integrate solar power obtained from homes or grids.</AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`6`}>
                                <AccordionTrigger>Why solar panels are expensive? </AccordionTrigger>
                                <AccordionContent>Solar panels are expensive for several reasons; some of which are high-quality photovoltaic cells and strong frames made of construction-grade materials. Furthermore, it also involves the cost of technology upgradation and efficiencies. Some of these costs encompass labor and other installation fees such as mounting structures. Moreover, government regulations coupled with imported panel tariffs, inverter, and balance-of-system components prices together determine the total sum spent on this alternative source of power. It is anticipated that solar panel prices will decrease over time through technological advancements and economies of scale that will eventually make renewable energy widespread and affordable</AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`7`}>
                                <AccordionTrigger>How many solar panels does it take to power a house? </AccordionTrigger>
                                <AccordionContent>The quantity of solar panels required to fuel a house using sunlight relies on how much power is used in the residence, its location, and also efficiency of the modules. As an example, residential solar power in America can be about twenty to twenty-five solar panels each with 300 watts to cater to a typical home. However, this is not conclusive since it varies greatly and would require a professional solar assessment to identify what will be enough or right considering unique usage requirements, local climatic conditions as well as sunshine availability.</AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={`8`}>
                                <AccordionTrigger>How long do solar panels last?</AccordionTrigger>
                                <AccordionContent>Some solar panels last for 25-30 years. An average degradation rate of .5% p.a exists. Regular maintenance and upgrading technology options improve the lifetime of the panel. However, it is also known that warranties cover more than 25 years with good performance past this period. The efficiency can be maintained through monitoring and cleaning activities. As a result, the next generation of solar panels might even exceed these in terms of longevity making them long-lasting investments in renewable energy infrastructure. For more details, you can contact the top inverter dealer & <Link to={"/our-company"}>solar inverter distributor</Link>.</AccordionContent>
                            </Accordion.Item>
                        </Accordion.Root>
                    </div>
                </div>
            </section>

        </>

    );
};

export default FaqSection;
