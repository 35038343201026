import React, { useState, useEffect, useContext } from 'react';
import "./KusumYojana.css"
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import kusum1 from "../../assets/kusum/kusum-1.webp";
import kusum2 from "../../assets/kusum/kusum-2.webp";
import kusum3 from "../../assets/kusum/kusum-3.webp";
import kusum4 from "../../assets/kusum/kusum-4.webp";
import kusum5 from "../../assets/kusum/kusum-5.webp";

import kusum6 from "../../assets/kusum/kusum-6.webp"
import kusum7 from "../../assets/kusum/kusum-7.webp"
import kusum8 from "../../assets/kusum/kusum-8.webp"
import kusum9 from "../../assets/kusum/kusum-9.webp"

import kusum10 from "../../assets/kusum/kusum-10.svg"
import kusum11 from "../../assets/kusum/kusum-11.webp"

import kusum12 from "../../assets/kusum/kusum-12.webp"
import kusum13 from "../../assets/kusum/kusum-13.webp"
import kusum14 from "../../assets/kusum/kusum-14.webp"
import kusum15 from "../../assets/kusum/kusum-15.webp"
import kusum16 from "../../assets/kusum/kusum-16.webp"

import kusum18 from "../../assets/kusum/kusum-18.webp"

import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import { AppContext } from '../../AppContext';

const NavbarHeight = 100;

const KusumYojana = () => {
  const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
    defaultValues: {
      enquiry_type: '0'
    }
  });
  const { states, city, setSelectedState } = useContext(AppContext);

  const selectedState = watch('state')

  useEffect(() => {
    setSelectedState(selectedState);
  }, [selectedState, setSelectedState]);

  const Navigate = useNavigate()

  const [captchaError, setCaptchaError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleVerificationSuccess = (token) => {
    setCaptchaToken(token);
  };

  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset - NavbarHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };


  const onSubmit = async (data) => {
    data.captchaToken = captchaToken;
    const formBody = new URLSearchParams(data).toString();
    try {
      const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });
      const responseData = await response.json();
      if (response.ok) {
        reset()
        Navigate("/thank-you")
      } else {
        if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
          setCaptchaError(responseData.msg);
        } else {
          console.error('Failed to submit the form:', responseData.error);
        }
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  const steps = [
    {
      title: "Go to the Official Website",
      description: "Visit the official PM-KUSUM scheme website or the state implementing agency's portal in question.",
      bgColor: " rgb(255 243 224 / 48%)",
      textColor: "#f57c00"
    },
    {
      title: "Complete the Application Form",
      description: "Fill out the online application form completely, including the personal and farm information requested.",
      bgColor: "rgb(255 232 232 / 48%)",
      textColor: "#e53935"
    },
    {
      title: "Send in the Required Documents",
      description: "Upload the required files, including identification documents, titles to real estate, and bank account information.",
      bgColor: "rgb(232 245 233 / 48%)",
      textColor: "#43a047"
    },
    {
      title: "Application Review",
      description: "The relevant authorities will examine the application. If accepted, a confirmation will be sent to you.",
      bgColor: "rgb(243 229 245 / 48%)",
      textColor: "#8e24aa"
    },
    {
      title: "Establishment and Bonus",
      description: "The solar panel or pump will be installed after approval, and the KUSUM Yojana subsidy will be given in accordance with the scheme's standards.",
      bgColor: "rgb(255 243 224 / 48%)",
      textColor: "#f57c00"
    }
  ];

  return (
    <>
      <section className="about-banner kusum-banner">
        <div className="custom-text custom-text-about">
          <p className='custom-title'>PM-KUSUM Scheme<span className='color-dot'>.</span></p>
          <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'PPA', url: '/ppa' }]} />
        </div>
      </section>


      <section className='kusum-main-section c-container'>
        <div className="kusum-main-header">
          <p className='kusum-header-small-title'>PM-KUSUM Scheme</p>
          <p className='kusum-header-big-title'>Objectives, Benefits, Components & Steps to Register</p>
          <p className='kusum-header-desc'>The PM-KUSUM (Pradhan Mantri Kisan Urja Suraksha evam Utthaan Mahabhiyan) is a transformative initiative by the Government of India aimed at empowering farmers through the use of solar energy. This scheme not only supports farmers financially but also promotes the adoption of renewable energy sources across the agricultural sector. At Kesrinandan, we will break down the primary goals of the PM-KUSUM yojana, the various advantages it offers to farmers, the key elements that constitute the program, and the step-by-step registration procedures to help farmers take full advantage of this initiative.</p>
        </div>

        <div className="ksusum-main-content">
          <aside className="sidebar">
            <div className="sidebar-content">
              <ul>
                <li><a onClick={(event) => handleLinkClick(event, 'kusum-undestanding')} className='sidebar-link'>Understanding the PM-KUSUM Initiative</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'objectives')} className='sidebar-link'>Objectives Of PM-KUSUM Program</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'components')} className='sidebar-link'>Components of the PM-KUSUM Scheme</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'benefits')} className='sidebar-link'>Benefits Of PM-KUSUM Project</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'steps-section')} className='sidebar-link'>Steps to Register for the PM-KUSUM Scheme</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'initiative')} className='sidebar-link'>How to Invest in the PM-KUSUM Initiative</a></li>
                <li><a onClick={(event) => handleLinkClick(event, 'invest')} className='sidebar-link'>Steps To Invest in PM-KUSUM Program</a></li>
              </ul>
              <div className="sidebar-cta">
                <h3>Go Solar Today! 🌞</h3>
                <p>Join the clean energy movement and start saving on your energy bills. Register your solar panels now and enjoy sustainable power for years to come!</p>
                <button>Register Now</button>
              </div>
            </div>
          </aside>

          <main className="kusum-content">
            <section className='kusum-undestanding' id='kusum-undestanding'>
              <img src={kusum1} alt="Understanding PM-KUSUM" />
              <div className="understanding-content">
                <h2>Understanding the The PM-KUSUM Initiative</h2>
                <p>There are a number of initiatives that make up the PM Kisan KUSUM Yojana, which aims to improve solar energy utilization in agriculture. These consist of the installation of freestanding solar agriculture pumps, solarization of current pumps, and decentralized solar power plants. Farmers that take part in the Kusum Yojna can drastically lessen their need for conventional energy sources.</p>
              </div>
            </section>

            <section id='objectives'>
              <h2>Objectives Of The PM-KUSUM Program</h2>
              <p>Here are the key objectives of the PM-KUSUM yojana</p>
              <div className="objectives">
                <ul>
                  <li>
                    <div className="objective-img">
                      <img src={kusum2} alt="" />
                    </div>
                    <div>
                      <h3>Minimize Carbon Footprint</h3>
                      <p>To reduce reliance on conventional energy sources, promote the use of solar energy in agriculture.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img">
                      <img src={kusum3} alt="" />
                    </div>
                    <div>
                      <h3>Empower Farmers</h3>
                      <p>Lower the electricity costs for farmers by giving them access to a dependable and sustainable energy source.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img">
                      <img src={kusum4} alt="" />
                    </div>
                    <div>
                      <h3>Boost Farmers' Income</h3>
                      <p>PM Kisan KUSUM Yojana gives farmers the option to resell any excess electricity produced by their solar plants to the grid, creating a new revenue stream.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img">
                      <img src={kusum5} alt="" />
                    </div>
                    <div>
                      <h3>Minimize Carbon Footprint</h3>
                      <p>By cutting greenhouse gas emissions, farmers can also help ensure a sustainable environment.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </section>

            <section id='components'>
              <h2>Components of The PM-KUSUM Scheme</h2>
              <p>There are four essential elements make up the PM-KUSUM Yojana, which promotes the use of solar energy in agriculture:</p>
              <div className="components">
                <div className="component">
                  <img src={kusum6} alt="Component A" />
                  <h3>Component A</h3>
                  <p>Installing distributed, grid-connected, ground-based renewable power plants with a 2 MW maximum capacity is Component A.</p>
                </div>
                <div className="component">
                  <img src={kusum7} alt="Component B" />
                  <h3>Component B</h3>
                  <p>To improve irrigation sustainability, separate solar-powered agricultural pumps will be installed to replace the current diesel pumps.</p>
                </div>
                <div className="component">
                  <img src={kusum8} alt="Component C (IPS)" />
                  <h3>Component C (IPS)</h3>
                  <p>Solarization of current grid-connected agricultural pumps, allowing farmers to utilize solar electricity for irrigation.</p>
                </div>
                <div className="component">
                  <img src={kusum9} alt="Component C (FLS)" />
                  <h3>Component C (FLS)</h3>
                  <p>Solarization of current agricultural pumps with a focus on financing the solar infrastructure to improve sustainability and coverage.</p>
                </div>
              </div>
            </section>

            <section id='benefits'>
              <h2>Benefits Of The PM-KUSUM Project</h2>
              <p>For farmers, the PM Kisan KUSUM Yojana provides a number of advantages:</p>
              <div className="benefits">
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Subsidized Solar Pumps</h3>
                  </div>
                  <p>The Kusum Yojana subsidy allows farmers to get subsidies for solar pumps, bringing down the cost of solar energy.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Energy Independence</h3>
                  </div>
                  <p>The Kusum Solar Panel Yojana's solar pumps guarantee a steady supply of electricity for irrigation, increasing crop yield.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Cost Savings</h3>
                  </div>
                  <p>Farmers can cut their electricity costs dramatically by converting to solar power.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Income Generation</h3>
                  </div>
                  <p>By selling excess electricity to the grid under the PM Kisan Kusum Yojana, farmers can generate additional revenue.</p>
                </div>
                <div className="benefit">
                  <div className="benefit-img">
                    <img src={kusum10} alt="Benefit 1" />
                  </div>
                  <div className='benefit-heading'>
                    <h3>Environmental Benefits</h3>
                  </div>
                  <p>Using solar energy contributes to the promotion of sustainable farming methods and the reduction of carbon emissions.</p>
                </div>
              </div>
            </section>

            <section className="steps-section" id='steps-section'>
              <h2>Steps to Register for The PM-KUSUM Scheme</h2>
              <p>To sign up for the PM-KUSUM scheme, follow these steps</p>
              <div className="steps-container">
                {steps.map((step, index) => (
                  <div className="step-card" key={index} style={{ backgroundColor: step.bgColor }}>
                    <h3 style={{ color: step.textColor }}>{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                ))}
              </div>
            </section>

            <section className='kusum-undestanding initiative' id='initiative'>
              <div className="understanding-content">
                <h2>How Can You Invest In The PM-KUSUM Intiative</h2>
                <p>Investing in the PM-KUSUM Yojana is a wise approach to take advantage of government incentives while simultaneously supporting sustainable agriculture. The Pradhan Mantri Kisan Urja Suraksha evam Utthaan Mahabhiyan, or PM-KUSUM Solar Yojana, intends to give farmers solar-powered irrigation and other agricultural needs solutions.</p>
              </div>
              <img src={kusum11} alt="Understanding PM-KUSUM" />
            </section>

            <section id='invest'>
              <h2>Steps To Invest in The PM-KUSUM Program</h2>
              <div className="objectives invest">
                <ul>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum12} alt="" />
                    </div>
                    <div>
                      <h3>Research And Understand The Scheme</h3>
                      <p>Start by acquainted with the specifics of PM Kisan Kusum Yojana. It is essential to comprehend its constituent parts, including the Solar Pump Kusum Yojana and the Kusum Solar Yojana, to make informed decisions.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum13} alt="" />
                    </div>
                    <div>
                      <h3>Verify Your Eligibility</h3>
                      <p>Make sure you fulfill the requirements to receive the Kusum Yojana subsidy. Farmers and cooperative societies are the scheme's primary target audience, and depending on the Yojana's particular component, there may be different qualifying conditions.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum14} alt="" />
                    </div>
                    <div>
                      <h3>Application Process</h3>
                      <p>To apply for the Kusum Solar Panel Yojana, go to the official website or get in touch with your local agricultural offices. Usually, the application procedure entails completing forms and providing the required paperwork to prove your eligibility and interest.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum15} alt="" />
                    </div>
                    <div>
                      <h3>Financial Planning</h3>
                      <p>Over time, solar solution investments may prove to be a cost-effective measure. To make the investment more accessible, look into the different financing options available, such as government subsidies through the Kusum Yojana subsidy plan.</p>
                    </div>
                  </li>
                  <li>
                    <div className="objective-img invest-img">
                      <img src={kusum16} alt="" />
                    </div>
                    <div>
                      <h3>Installation and Implementation</h3>
                      <p>After receiving approval, collaborate with approved vendors to install the solar panels. Proper installation is crucial for maximizing benefits, whether for grid-connected solar panels or a standalone solar pump.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </main>
        </div>
      </section>

      <section className="info-cta-section">
        <div className="info-cta-section-content c-container">
          <div className="info-cta-content">
            <div className="info-cta-image">
              <img src={kusum18} alt="Sustainable Agriculture" />
            </div>
            <div className="info-cta-text">
              <p>
                A big step toward sustainable agriculture and farmer energy independence is the PM Kisan KUSUM yojana. Through comprehension of the goals, advantages, and enrollment procedure, farmers can optimize this endeavor to enhance their means of subsistence and make a positive impact on the environment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-contact-form">
        <div className="inner-contact-form-content c-container">
          <div className="inner-contact-form-heading">
            Contact Us
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="vertical-form">
            <div className="form-container">
              <div className="form-group">
                <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                {errors.name && <label className="error-message">{errors.name.message}</label>}
              </div>

              <div className="form-group">
                <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                {errors.email && <label className="error-message">{errors.email.message}</label>}
              </div>

              <div className="form-group">
                <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                  required: 'Required', pattern: {
                    value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                    message: 'Invalid phone number',
                  }
                })} />
                {errors.phone && <label className="error-message">{errors.phone.message}</label>}
              </div>

              <div className="form-group">
                <Controller
                  name="service_name"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a service' }}
                  render={({ field }) => (
                    <select {...field} className='form-control' onChange={(e) => setValue('service_name', e.target.value)}>
                      <option value="" disabled>Select a service</option>
                      <option value="Solar Panels">Solar Panels</option>
                      <option value="Solar Panels">Inverters</option>
                      <option value="Solar Panels">Cable Wires</option>
                      <option value="Solar Panels">PPA</option>
                    </select>
                  )}
                />
                {errors.service_name && <label className="error-message">{errors.service_name.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="state"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a state' }}
                  render={({ field }) => (
                    <select
                      className='form-control'
                      {...field} onChange={(e) => setValue('state', e.target.value)}>
                      <option value="" disabled>State</option>
                      {states?.map((el) => {
                        return (
                          <option key={el?.id} value={el?.id}>
                            {el?.state}
                          </option>
                        )
                      })}
                    </select>
                  )}
                />
                {errors.state && <label className="error-message">{errors.state.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="city"
                  defaultValue={""}
                  control={control}
                  rules={{ required: 'Please select a city' }}
                  render={({ field }) => (
                    <select
                      className='form-control'
                      {...field} onChange={(e) => setValue('city', e.target.value)}>
                      <option value="" disabled>City</option>
                      {city?.map((el) => {
                        return (
                          <option key={el?.id} value={el?.id}>
                            {el?.city_name}
                          </option>
                        )
                      })}
                    </select>
                  )}
                />
                {errors.city && <label className="error-message">{errors.city.message}</label>}

              </div>

              <div className="form-group">
                <Controller
                  name="type"
                  defaultValue={""}
                  control={control}
                  rules={{
                    required: 'Please select a type'
                  }}

                  render={({ field }) => (
                    <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                      <option value="" disabled>Select a type</option>
                      <option value="dealer">Dealer</option>
                      <option value="retailer">Retailer</option>
                      <option value="distributor">Distributor</option>
                      <option value="epc player">EPC Player</option>
                      <option value="consumer">Consumer</option>
                    </select>
                  )}
                />
                {errors.type && <div className="error-message">{errors.type.message}</div>}
              </div>

              <div className="form-group" >
                <textarea placeholder='Your message' className='form-control' {...register('message', { required: 'Required' })} />
                {errors.message && <label className="error-message">{errors.message.message}</label>}
              </div>

              <input type="hidden" {...register('enquiry_type')} />

              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                  onChange={handleVerificationSuccess}
                />
                {captchaError && <div className="error-message">{captchaError}</div>}
              </div>

            </div>
            <div className="form-footer">
              <button className='common-btn enquiry-btn' type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>

    </>
  )
}

export default KusumYojana