import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { Helmet } from 'react-helmet';
import './BreadCrumb.css';

const Breadcrumb = ({ paths, onClick }) => {
    const homeUrl = '/';

    const breadcrumbListJsonLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": paths.map((path, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "name": path.label,
            "item": `https://kesrinandan.com${path.url || homeUrl}`,
        })),
    };

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(breadcrumbListJsonLd)}
                </script>
            </Helmet>
            <nav aria-label="breadcrumb" className="breadcrumb-container">
                <ol className="breadcrumb">
                    {paths.map((path, index) => (
                        <li key={index} className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`}>
                            {index === paths.length - 1 ? (
                                <span>{path.label}</span>
                            ) : (
                                <>
                                    <Link to={path.url || homeUrl} onClick={path.onClick}>{path.label}</Link>
                                    <IoIosArrowForward className="breadcrumb-icon" />
                                </>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </>
    );
};

export default Breadcrumb;
