import React, { useState, useEffect } from 'react';
import "./Blogs.css";
import BlogSectionCard from '../../components/BlogSection/BlogSectionCard';
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom';

const Blogs = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/blog/all`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content=" Explore a diverse range of topics, from solar panels to inverters, in Kesrinandan's blog section. Gain valuable insights and fresh perspectives. Read more!"
                />
                <title>Stay Updated With Our Blogs | Kesrinandan Ventures</title>
                <meta property="og:url" content="https://kesrinandan.com/blogs" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Stay Updated With Our Blogs | Kesrinandan Ventures" />
                <meta property="og:description" content=" Explore a diverse range of topics, from solar panels to inverters, in Kesrinandan's blog section. Gain valuable insights and fresh perspectives. Read more!" />
                <meta property="og:image" content="https://kesrinandan.com/static/media/kesrinandan.4348074cbbe19e71283609c0c0969ee2.svg"></meta>
                <link rel="canonical" href={window?.location?.href} />
            </Helmet>
            <section className="about-banner blogs-banner">
                <div className="custom-text custom-text-about">
                    <h1 className='custom-title'>Blogs<span className='color-dot'>.</span></h1>
                    <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Blogs', url: '/blogs' }]} />
                </div>
            </section>

            <div className="c-container">
                <section className="blogs-section">

                    {loading ? <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                    /> : data?.map((el) => {
                        return (
                            <div key={el?.id} className='blog-section-card'>
                                <img src={el?.image} alt={el?.alt_tag} />
                                <p className='common-btn blog-btn'>{el?.created}</p>
                                <h2 className='blog-card-title'>{el?.title}</h2>
                                <p className='blog-card-desc'>{el?.short_description.length > 250 ? `${el?.short_description.substring(0, 250)} ...` : el?.short_description}</p>
                                <Link to={`/blog-detail/${el?.slug}`}>
                                    <p className='blog-card-btn' >Read More</p>
                                </Link>
                            </div>
                        )
                    })}

                </section>
            </div>
        </>
    )
}

export default Blogs