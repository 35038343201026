import React, { useState, useEffect, useContext } from 'react';
import "./Contact.css";

import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';


import BigTitle2 from '../../components/BigTitle/BigTitle2';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

import logo1 from "../../assets/footer/footer-mail.svg";
import logo2 from "../../assets/footer/footer-phone.svg";
import logo3 from "../../assets/footer/ffoter-loc.svg";
import bg from "../../assets/Frame.png";
import { AppContext } from '../../AppContext';


const Contact = () => {
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            enquiry_type: '0'
        }
    });
    const { states, city, setSelectedState } = useContext(AppContext);
    const Navigate = useNavigate()

    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const selectedState = watch('state')

    useEffect(() => {
        setSelectedState(selectedState);
    }, [selectedState, setSelectedState]);


    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/quote-enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });
            const responseData = await response.json();
            if (response.ok) {
                reset()
                Navigate("/thank-you")
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };


    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Get in touch with Kesrinandan for inquiries, or partnerships. Our team is ready to assist you. Reach out today to explore how we can collaborate for mutual success."
                />
                <title>Reach Out for Inquiries and Collaborations | Kesrinandan Ventures</title>
                <meta property="og:url" content="https://kesrinandan.com/contact" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Reach Out for Inquiries and Collaborations | Kesrinandan Ventures" />
                <meta property="og:description" content="Get in touch with Kesrinandan for inquiries, or partnerships. Our team is ready to assist you. Reach out today to explore how we can collaborate for mutual success." />
                <meta property="og:image" content="https://kesrinandan.com/static/media/kesrinandan.4348074cbbe19e71283609c0c0969ee2.svg"></meta>
                <link rel="canonical" href={window?.location?.href} />
            </Helmet>
            <section className="about-banner contact-banner ">
                <div className="custom-text custom-text-about">
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Contact', url: '/contact' }]} />
                    </div>
                </div>
            </section>

            <section className="contact-container">
                <div className="c-container">
                    <div className="banner-heading-section banner-heading-section2">
                        <BigTitle2 heading="Contact Us" />
                    </div>
                    <div className="contact-section">
                        <div className="contact-info-card">
                            <div className="contact-info-header">
                                <h3>Head Office</h3>
                                <h6>Kota</h6>
                            </div>
                            <ul className="contact-info-data">
                                <Link to={"mailto:info@kesrinandan.com"}>
                                    <li>
                                        <div className="info-data-logo">
                                            <img src={logo1} alt="" />
                                        </div>
                                        <div className="info-content">
                                            <h6>Email</h6>
                                            <p>info@kesrinandan.com</p>
                                        </div>
                                    </li>
                                </Link>

                                <Link to={"tel:+91-9828118888"}>
                                    <li>
                                        <div className="info-data-logo">
                                            <img src={logo2} alt="" />
                                        </div>
                                        <div className="info-content">
                                            <h6>Contact</h6>
                                            <p>+91-9828118888</p>
                                        </div>
                                    </li>
                                </Link>
                                <li>
                                    <div className="info-data-logo">
                                        <img src={logo3} alt="" />
                                    </div>
                                    <div className="info-content">
                                        <h6>Location</h6>
                                        <p>Shop No. 33-34, Near Hanuman Temple, Talwandi, Kota, Rajasthan 324005</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="contact-info-card">
                            <div className="contact-info-header">
                                <h3>Branch Office</h3>
                                <h6>Jaipur</h6>
                            </div>
                            <ul className="contact-info-data">
                                <Link to={"mailto:info@kesrinandan.com"}>
                                    <li>
                                        <div className="info-data-logo">
                                            <img src={logo1} alt="" />
                                        </div>
                                        <div className="info-content">
                                            <h6>Email</h6>
                                            <p>info@kesrinandan.com</p>
                                        </div>
                                    </li>
                                </Link>

                                <Link to={"tel:+91-9414459015"}>
                                    <li>
                                        <div className="info-data-logo">
                                            <img src={logo2} alt="" />
                                        </div>
                                        <div className="info-content">
                                            <h6>Contact</h6>
                                            <p>+91-9414459015</p>
                                        </div>
                                    </li>
                                </Link>
                                <li>
                                    <div className="info-data-logo">
                                        <img src={logo3} alt="" />
                                    </div>
                                    <div className="info-content">
                                        <h6>Location</h6>
                                        <p>Flat No. 803, Gomati Apartment, Pratap Nagar, Sanganer, Jaipur, Rajasthan 302033</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div className="c-container">
                <section className="contact-form-container">
                    <div className="contact-form contact-page-form">
                        <div className="location-banner-header">
                            <h6 className="banner-small-title">Contact</h6>
                            <h4 className="banner-big-title banner-big-title-contact">Get In Touch With Us</h4>

                            <form onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                                <div className="form-container">
                                    <div className="form-group">
                                        <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                        {errors.name && <label className="error-message">{errors.name.message}</label>}
                                    </div>

                                    <div className="form-group">
                                        <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                        {errors.email && <label className="error-message">{errors.email.message}</label>}
                                    </div>

                                    <div className="form-group">
                                        <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                            required: 'Required', pattern: {
                                                value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                message: 'Invalid phone number',
                                            }
                                        })} />
                                        {errors.phone && <label className="error-message">{errors.phone.message}</label>}
                                    </div>

                                    <div className="form-group">
                                        <Controller
                                            name="service_name"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select a service' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control' onChange={(e) => setValue('service_name', e.target.value)}>
                                                    <option value="" disabled>Select a service</option>
                                                    <option value="Solar Panels">Solar Panels</option>
                                                    <option value="Solar Panels">Inverters</option>
                                                    <option value="Solar Panels">Cable Wires</option>
                                                    <option value="Solar Panels">PPA</option>
                                                </select>
                                            )}
                                        />
                                        {errors.service_name && <label className="error-message">{errors.service_name.message}</label>}

                                    </div>

                                    <div className="form-group">
                                        <Controller
                                            name="state"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select a state' }}
                                            render={({ field }) => (
                                                <select
                                                    className='form-control'
                                                    {...field} onChange={(e) => setValue('state', e.target.value)}>
                                                    <option value="" disabled>State</option>
                                                    {states?.map((el) => {
                                                        return (
                                                            <option key={el?.id} value={el?.id}>
                                                                {el?.state}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                        {errors.state && <label className="error-message">{errors.state.message}</label>}

                                    </div>


                                    <div className="form-group">
                                        <Controller
                                            name="city"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select a city' }}
                                            render={({ field }) => (
                                                <select
                                                    className='form-control'
                                                    {...field} onChange={(e) => setValue('city', e.target.value)}>
                                                    <option value="" disabled>City</option>
                                                    {city?.map((el) => {
                                                        return (
                                                            <option key={el?.id} value={el?.id}>
                                                                {el?.city_name}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                        {errors.city && <label className="error-message">{errors.city.message}</label>}

                                    </div>

                                    <div className="form-group type-area-group">
                                        <Controller
                                            name="type"
                                            defaultValue={""}
                                            control={control}
                                            rules={{
                                                required: 'Please select a type'
                                            }}

                                            render={({ field }) => (
                                                <select {...field} className='form-control' onChange={(e) => setValue('type', e.target.value)}>
                                                    <option value="" disabled>Select a type</option>
                                                    <option value="dealer">Dealer</option>
                                                    <option value="retailer">Retailer</option>
                                                    <option value="distributor">Distributor</option>
                                                    <option value="epc player">EPC Player</option>
                                                    <option value="consumer">Consumer</option>
                                                </select>
                                            )}
                                        />
                                        {errors.type && <div className="error-message">{errors.type.message}</div>}
                                    </div>

                                    <div className="form-group text-area-group" >
                                        <textarea placeholder='Your message' className='form-control form-control-textarea' {...register('message', { required: 'Required' })} />
                                        {errors.message && <label className="error-message">{errors.message.message}</label>}
                                    </div>

                                    <input type="hidden" {...register('enquiry_type')} />

                                    <div className="form-group">
                                        <ReCAPTCHA
                                            sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                            onChange={handleVerificationSuccess}
                                        />
                                        {captchaError && <div className="error-message">{captchaError}</div>}
                                    </div>

                                </div>
                                <div className="form-footer">
                                    <button className='common-btn enquiry-btn' type="submit">Get In Touch</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="contact-page-map">
                        <div className="contact-page-location">
                            <iframe title='map' className='contact-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.5461590841223!2d75.84338327597389!3d25.151030033568126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396f84dbb48a309b%3A0x339f4303dfe28d6a!2sKesrinandan!5e0!3m2!1sen!2sin!4v1710582142444!5m2!1sen!2sin" height="700px" width="470px" style={{ border: "0", borderRadius: "16px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="contact-page-map-bg">
                            <img src={bg} alt="" className='map-bg' />
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Contact