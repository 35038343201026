import React from 'react';
import "./Location.css";
import { Helmet } from 'react-helmet';
import HomeLocationBannner from '../../components/HomeLocationBanner/HomeLocationBannner';
import BigTitle2 from '../../components/BigTitle/BigTitle2';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import Description from '../../components/Description/Description';
import { Link } from 'react-router-dom';

const Location = () => {
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Explore Kesrinandan's footprint in India. Uncover innovative projects, products, and our commitment to excellence in various states. Join us to know more!"
                />
                <title>Explore Our Locations in India: Kesrinandan Ventures</title>
                <meta property="og:url" content="https://kesrinandan.com/location" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Explore Our Locations in India: Kesrinandan Ventures" />
                <meta property="og:description" content="Explore Kesrinandan's footprint in India. Uncover innovative projects, products, and our commitment to excellence in various states. Join us to know more!" />
                <meta property="og:image" content="https://kesrinandan.com/static/media/kesrinandan.4348074cbbe19e71283609c0c0969ee2.svg"></meta>
                <link rel="canonical" href={window?.location?.href} />
            </Helmet>

            <section className="about-banner location-banner">
                <div className="custom-text custom-text-about">
                    <div className="breadcrumb-hide" style={{ visibility: "hidden" }}>
                        <Breadcrumb paths={[{ label: 'Home', url: '/' }, { label: 'Location', url: '/location' }]} />
                    </div>
                </div>
            </section>

            <section className="banner-heading-section banner-heading-section2">
                <div className="c-container">
                    <BigTitle2 heading="Our Locations" />
                    <Description desc="Discover the colorful web of Kersinandan Ventures Ltd.'s activities throughout India, where innovation and sustainable energy coexist harmoniously." />
                </div>
            </section>

            <section className="location-page-section">
                <div className="c-container">
                    <div id='rajasthan' className="location-page-item">
                        <h2 className="location-name">Rajasthan</h2>
                        <p className="location-desc">Based in Kota, our central office operates as the hub of our operations, coordinating a wide range of initiatives to transform the energy sector in Rajasthan. Our products, ranging from solar installations to EPC services, are the perfect combination of quality and sustainability.</p>
                    </div>

                    <div id='delhi' className="location-page-item">
                        <h2 className="location-name">Delhi</h2>
                        <p className="location-desc">Our Delhi base, tucked away amid the busy streets of the capital of India, is a symbol of creativity and teamwork. Here, we collaborate with governmental organizations, businesses, and other relevant parties to promote the use of renewable energy sources and cultivate a sustainable culture.</p>
                    </div>

                    <div id='maharashtra' className="location-page-item">
                        <h2 className="location-name">Maharashtra</h2>
                        <p className="location-desc"> Head west to Maharashtra, we are highly visible in Pune and Mumbai. We are committed to fostering the development of Maharashtra with creative solar solutions and progressive projects because we are leaders in the renewable energy space.</p>
                    </div>

                    <div id='madhya-pradesh' className="location-page-item">
                        <h2 className="location-name">Madhya Pradesh</h2>
                        <p className="location-desc">We are actively influencing the state's energy future in Bhopal and Indore, two major cities in central India where our presence is felt. We are bringing about significant change and empowering communities with sustainable energy solutions through innovative programs and smart partnerships.</p>
                    </div>

                    <div id='uttar-pradesh' className="location-page-item">
                        <h2 className="location-name">Uttar Pradesh</h2>
                        <p className="location-desc">Our presence stretches throughout Uttar Pradesh, from Lucknow to Kanpur, bringing forth a new era of clean, renewable energy. Here, we are dedicated to illuminating lives and propelling advancement with our extensive range of solar solutions.</p>
                    </div>

                    <div className="location-page-item">
                        <h2 className="location-name">Solar Panels Distributor</h2>
                        <p className="location-desc">Apart from our prime locations, Kersinandan Ventures Ltd. is proud to be a leading <Link to={"/"} target='_blank'> solar panel distributor </Link> in Bangalore, Hyderabad, Chennai, Pune, and Mumbai. Our vast network enables us to serve a wide range of clients, providing superior solar solutions and products that go above and beyond their expectations.</p>
                        <p className="location-desc" style={{ marginTop: "1rem" }}>Kersinandan Ventures Ltd. is your go-to partner for all things renewable energy, whether you need solar installations, modern solar panels, or <Link to={"/ppa"} target='_blank'>power purchase agreements</Link>. Come along with us as we bring sustainable solutions to India that will propel development and leave a lasting impression on coming generations.</p>
                    </div>
                </div>
            </section>
            <HomeLocationBannner />
        </>
    )
}

export default Location