import React, { createContext, useState, useEffect, useMemo } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchResults, setSearchResults] = useState();

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await fetch('https://kesrinandan.com/admin/api/state/all', {
          method: 'GET',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        if (response.ok) {
          const responseData = await response.json();
          setStates(responseData?.data || []);
        } else {
          console.error('Failed to fetch states');
        }
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedState) {
        try {
          const response = await fetch(`https://kesrinandan.com/admin/api/city/all?id=${selectedState}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          });
          if (response.ok) {
            const responseData = await response.json();
            setCity(responseData?.data || []);
          } else {
            console.error('Failed to fetch cities');
          }
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      }
    };
    fetchCities();
  }, [selectedState]);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await fetch('https://kesrinandan.com/admin/api/menu');
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const result = await response.json();
        setMenu(result?.data || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchMenu();
  }, []);

  const searchProducts = async (query) => {
    try {
      const response = await fetch(`https://kesrinandan.com/admin/api/product/all`);
      const data = await response.json();
      const filteredProducts = data?.data.filter(product => 
        product?.title?.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredProducts); 
    } catch (error) {
      console.error("Error searching products:", error);
    }
  };
  
  const contextValue = useMemo(() => ({
    states,
    city,
    menu,
    setSelectedState,
    loading,
    error,
    searchResults,
    searchProducts, 
  }), [states, city, menu, selectedState, loading, error, searchResults]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
