import React, { useState, useEffect, Suspense, useContext } from 'react'
import "../BrandDetail/BrandDetail.css"

import { Helmet } from 'react-helmet';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import partnerSvg from "../../assets/become-partner-vector.svg"
import { useForm, Controller } from 'react-hook-form';

import { RiMapPin4Line } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import { FiPhone } from "react-icons/fi";
import { LiaCitySolid } from "react-icons/lia";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { Link } from 'react-router-dom';

const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));

const Livguard = () => {
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
    const { states, city, setSelectedState } = useContext(AppContext);
    const [captchaError, setCaptchaError] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const Navigate = useNavigate()
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue } = useForm();
    const selectedState = watch('state')

    const handleFormInteraction = () => {
        if (!showReCAPTCHA) {
            setShowReCAPTCHA(true);
        }
    };

    useEffect(() => {
        setSelectedState(selectedState);
    }, [selectedState, setSelectedState]);

    const handleVerificationSuccess = (token) => {
        setCaptchaToken(token);
    };

    const onSubmit = async (data) => {
        data.captchaToken = captchaToken;
        const formBody = new URLSearchParams(data).toString();
        try {
            const response = await fetch('https://kesrinandan.com/admin/api/landingpageform', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            });

            const responseData = await response.json();

            if (response.ok) {
                reset()
                Navigate("/thank-you")
            } else {
                if (responseData.msg && responseData.msg === "Invalid CAPTCHA. Please try again") {
                    setCaptchaError(responseData.msg);
                } else {
                    console.error('Failed to submit the form:', responseData.error);
                }
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <FaPlus className={` toggle-on`} />
                <FaMinus className={`toggle-off`} />
            </Accordion.Trigger>
        </Accordion.Header>
    ));



    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));


    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <title>Landing Page</title>
            </Helmet>

            <section className="about-banner brands-detail-banner">
                <div className="custom-text custom-text-about">
                    <p className='custom-title' style={{ textTransform: "capitalize" }}>
                        Livguard<span className='color-dot'>.</span>
                    </p>
                </div>
            </section>

            <section class="brand-detail-section">
                <div>
                    <div class="brand-logo">
                        <h2>Livguard Products</h2>
                    </div>
                    <div class="brands-desc">
                        <div>Livguard Solar, a top solar company, offers solar rooftop panels for commercial and industrial sectors at affordable prices in India. Kesrinandan being a solar panel distributor of Livguard, provides high-quality solar panels with long-lasting performance. Within 9 years, Livguard has established its identity as the fastest-growing energy storage brand. With huge experience in the energy sector, they not only create solar solutions but also customize them to meet customer's unique needs, making them stand out from others.
                        </div>
                    </div>
                </div>
                <div>
                    <h2 class="product-ct">Inverter</h2>
                    <div class="brand-products">
                        <div class="products-item">
                            <Link to="/product-detail/pwm-off-grid-inverter-1500va">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-07-18-104833_2023.webp" alt="" />
                                </div>
                                <h5> PWM Off Grid Inverter (1500VA)</h5>
                            </Link>
                        </div>
                        <div class="products-item">
                            <Link to="/product-detail/pwm-off-grid-inverter">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-07-18-103853_2023.webp" alt="" />
                                </div>
                                <h5>PWM Off Grid Inverter </h5>
                            </Link>
                        </div>
                        <div class="products-item">
                            <Link to="/product-detail/mppt-off-grid-inverter">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-07-18-102355_2023.webp" alt="" />
                                </div>
                                <h5> MPPT Off Grid Inverter </h5>
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 class="product-ct">Solar Panel</h2>
                    <div class="brand-products">
                        <div class="products-item">
                            <Link href="/product-detail/poly-solar">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-02-09-110252_2023.png" alt="" />
                                </div>
                                <h5>Livguard Poly Solar</h5>
                            </Link>
                        </div>
                        <div class="products-item">
                            <Link to="/product-detail/mono-perc">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-02-09-110123_2023.png" alt="" />
                                </div>
                                <h5>Livguard Mono-Perc</h5>
                            </Link>
                        </div>
                        <div class="products-item">
                            <Link to="/product-detail/mono-perc-halfcut">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-02-09-105743_2023.png" alt="" />
                                </div>
                                <h5>Livguard Mono-Perc-Halfcut</h5>
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 class="product-ct">Battery</h2>
                    <div class="brand-products">
                        <div class="products-item">
                            <Link to="/product-detail/240ah-solar-battery">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-07-19-044626_2023.png" alt="" />
                                </div>
                                <h5>240Ah Solar Battery</h5>
                            </Link>
                        </div>
                        <div class="products-item">
                            <Link to="/product-detail/200ah-solar-battery">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-07-19-043731_2023.png" alt="" />
                                </div>
                                <h5>200Ah Solar Battery</h5>
                            </Link>
                        </div>
                        <div class="products-item">
                            <Link to="/product-detail/165ah-solar-battery">
                                <div class="products-item-img">
                                    <img src="https://kesrinandan.com/admin/uploads/product/2024-07-19-044213_2023.png" alt="" />
                                </div>
                                <h5>165Ah Solar Battery</h5>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="brands-desc brand-desc-2">
                    <div>
                        <h1>What is Livguard Solar Panel?&nbsp;</h1>

                        <p>Livguard Solar Panels are <a href="https://www.livguardsolar.com/solar-panels/?language=en-in#:~:text=Livguard Solar Panels are Polycrystalline,Capacity">Polycrystalline/mono perc PV panels</a>, IEC compliant having a range from 40 W – 325 W. These panels are ideally suited for commercial and residential uses. Livguard Solar is a device that turns sun raises into electricity using special cells called photovoltaic (PV) cells. These PV cells invigorated electrons when exposed to light. The electrons flow through a circuit, creating direct current (DC) electricity, which can power various devices or be stored in batteries. Livguard solar panels are equipped with modern technology, making this energy form utilizable just like any other.</p>

                        <h3>Benefits of Livguard Solars:</h3>

                        <ul>
                            <li>
                                <p><strong>Reduced Electricity Bills:</strong> Produce your clean energy and significantly reduce on-grid dependence. You pay only for the energy from solar panels, leaving a considerable amount for other expenditures.</p>
                            </li>
                            <li>
                                <p><strong>End-to-end Installation:</strong> Livguard offers end-to-end installation services for their solar panels, delivering a seamless and hassle-free solution from start to finish.</p>
                            </li>
                            <li>
                                <p><strong>Low Maintenance: </strong>Livguard solar panels are designed to have low operation and maintenance costs, offering tough construction with minimal upkeep requirements.</p>
                            </li>
                            <li>
                                <p><strong>Tailored Solutions: </strong>With years of experience in energy, Livguard creates solar solutions tailored to your specific needs. This special skill makes them different from the rest of the others. Their affordable solar systems run smoothly with little maintenance, giving you clean and efficient energy for a hassle-free experience!</p>
                            </li>
                        </ul>

                        <h2>Invest in a Brighter Tomorrow with Kesrinandan:</h2>

                        <p>Being a leading solar brand in India, it uses quality materials and strict manufacturing methods to ensure its products are reliable and safe. Their panels pass tough quality tests to ensure they work great, even in tough weather. Shop Livguard solar panels today made of high-quality and durable materials.&nbsp;</p>
                    </div>
                </div>
            </section>

            <div className="landing-page-section-bg">
                <div className="c-container">
                    <section className="become-partner">
                        <div className="partner-content">
                            <div className="partner-info">
                                <h2>Become a Dealer</h2>
                                <p>Join Kesrinandan Ventures today and start your own business with our solar panel dealership program. This is the best opportunity to expand your business in the rapidly growing solar industry in various cities. Sign up today and profit from the highly demanding solar energy solutions.</p>
                            </div>
                            <img src={partnerSvg} alt="Descriptive Alt Text" className="partner-image" />
                        </div>
                        <div className="partner-form">
                            <form onFocus={handleFormInteraction} onClick={handleFormInteraction} onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                                <div className="form-container form-container2">
                                    <div className="form-group">
                                        <div className="icon">
                                            <LuUser2 />
                                        </div>
                                        <input type="text" className='form-control' placeholder='Your name' {...register('name', { required: 'Required' })} />
                                        {errors.name && <div className="error-message">{errors.name.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <FiPhone />
                                        </div>
                                        <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                            required: 'Required', pattern: {
                                                value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                message: 'Invalid phone number',
                                            }
                                        })} />
                                        {errors.phone && <div className="error-message">{errors.phone.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="product"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select Product' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Select Product</option>
                                                    <option value="Solar Panels">Solar Panels</option>
                                                    <option value="Inverters">Inverters</option>
                                                    <option value="Cables Wires">Cables Wires</option>
                                                </select>
                                            )}
                                        />
                                        {errors.product && <div className="error-message">{errors.product.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <RiMapPin4Line />
                                        </div>
                                        <Controller
                                            name="state"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select a state' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control' onChange={(e) => setValue('state', e.target.value)}>
                                                    <option value="">Select a state</option>
                                                    {
                                                        states
                                                            .filter(el => el.state === 'Rajasthan') 
                                                            .map((el) => (
                                                                <option key={el.id} value={el.id}>
                                                                    {el.state}
                                                                </option>
                                                            ))}
                                                </select>
                                            )}
                                        />
                                        {errors.state && <div className="error-message">{errors.state.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <LiaCitySolid />
                                        </div>
                                        <Controller
                                            name="city"
                                            defaultValue={""}
                                            control={control}
                                            rules={{
                                                required: 'Please select a city'
                                            }}

                                            render={({ field }) => (
                                                <select {...field} className='form-control' onChange={(e) => setValue('city', e.target.value)}>
                                                    <option value="" disabled>Select a city</option>
                                                    {city?.map((el) => {
                                                        return (
                                                            <option key={el?.id} value={el?.id}>
                                                                {el?.city_name}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        />
                                        {errors.city && <div className="error-message">{errors.city.message}</div>}
                                    </div>

                                    <div className="form-group">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="godown_available"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Is Godwon Area Available?</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            )}
                                        />
                                        {errors.godown_available && <div className="error-message">{errors.godown_available.message}</div>}
                                    </div>

                                    <div className="form-group w-100">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="interested"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>Need to submit 10 Lac as a security amount. Interested?</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            )}
                                        />
                                        {errors.interested && <div className="error-message">{errors.interested.message}</div>}
                                    </div>

                                    <div className="form-group w-100">
                                        <div className="icon">
                                            <VscTypeHierarchySub />
                                        </div>
                                        <Controller
                                            name="capital_invest"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please Select' }}
                                            render={({ field }) => (
                                                <select {...field} className='form-control'>
                                                    <option value="" disabled>How much capital you can invest?(Lac)</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            )}
                                        />
                                        {errors.capital_invest && <div className="error-message">{errors.capital_invest.message}</div>}
                                    </div>


                                    <div className="form-group text-area-group w-100" >
                                        <textarea placeholder='Your message' className='form-control form-control-textarea' {...register('message', { required: 'Required' })} />
                                        {errors.message && <div className="error-message">{errors.message.message}</div>}
                                    </div>

                                    <div className="form-group w-100">
                                        {showReCAPTCHA && (
                                            <Suspense fallback={<div>Loading reCAPTCHA...</div>}>
                                                <ReCAPTCHA
                                                    sitekey="6Lc5zpQpAAAAAO9suPoYtJaeB5r57wNwKbkCbSjX"
                                                    onChange={handleVerificationSuccess}
                                                />
                                            </Suspense>
                                        )}
                                        {captchaError && <div className="error-message">{captchaError}</div>}
                                    </div>

                                </div>
                                <div className="form-footer">
                                    <button className='common-btn enquiry-btn' type="submit">Submit Request</button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>

            <section className="faq-section">
                <div className="c-container">
                    <h2 className="description">If you have any questions, we will be very happy to answer them</h2>
                    <div className="faq-accordion-section">
                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                            <Accordion.Item className="AccordionItem" value={1}>
                                <AccordionTrigger> Is it true that the solar panels made by Livguard last forever?</AccordionTrigger>
                                <AccordionContent>A Liveguard solar panel is especially evidenced by its longevity, usually over 25 years of performance with a little service.
                                </AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={2}>
                                <AccordionTrigger>Can you project a solar power system and finance it?</AccordionTrigger>
                                <AccordionContent>Yes, some banks and other financial institutions provide loan plans specifically designed for commercial solar panel installations. Our team will be able to help you identify financing options for your farm.
                                </AccordionContent>
                            </Accordion.Item>
                            <Accordion.Item className="AccordionItem" value={3}>
                                <AccordionTrigger>Why should I buy Livguard solar panel?</AccordionTrigger>
                                <AccordionContent>Livguards works on Aim & Act process. It means Aiming outside your comfort zone, Communicating honestly, and listen carefully and work with Trust and creating winning Teams.
                                </AccordionContent>
                            </Accordion.Item>
                        </Accordion.Root>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Livguard