import React, { useState, useEffect } from 'react';
import "./BrandDetail.css"
import { useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';
import CtaSection from '../../components/CtaSection/CtaSection';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { FaPlus, FaMinus } from "react-icons/fa";
import { Link } from 'react-router-dom';

const BrandDetail = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams()
    const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Header className="AccordionHeader">
            <Accordion.Trigger
                className={classNames('AccordionTrigger', className)}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <FaPlus className={` toggle-on`} />
                <FaMinus className={`toggle-off`} />
            </Accordion.Trigger>
        </Accordion.Header>
    ));



    const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
        <Accordion.Content
            className={classNames('AccordionContent', className)}
            {...props}
            ref={forwardedRef}
        >
            <div className="AccordionContentText">{children}</div>
        </Accordion.Content>
    ));

    // Add this function to group products by category_id
    const groupProductsByCategoryId = (products) => {
        const grouped = products.reduce((acc, product) => {
            const categoryId = product.category_id;
            if (!acc[categoryId]) {
                acc[categoryId] = [];
            }
            acc[categoryId].push(product);
            return acc;
        }, {});
        return grouped;
    };

    // Add this function to render products by category_id
    const renderProductsByCategoryId = (products) => {
        const groupedProducts = groupProductsByCategoryId(products);
        const categoryNames = {
            108: 'Battery',
            29: 'Inverter',
            30: 'Solar Panel',
        };

        return Object.keys(groupedProducts).map((categoryId, index) => (
            <div key={index}>
                <h2 className='product-ct'>{categoryNames[categoryId]}</h2>
                <div className='brand-products'>
                    {groupedProducts[categoryId].map((product) => (
                        <div className="products-item" key={product.id}>
                            <Link to={`/product-detail/${product.slug}`}>
                                <div className="products-item-img">
                                    <img src={product.image} alt="" />
                                </div>
                                <h5>{product.title}</h5>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://kesrinandan.com/admin/api/partner/details?slug=${slug}`)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                setData(result?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    console.log(data)

    return (
        <>
            <Helmet>
                <title>{data?.meta_title}</title>
                <meta
                    name="description"
                    content={data?.meta_description}
                />
                <meta property="og:url" content={`https://kesrinandan.com/brand-detail/${slug}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data?.meta_title} />
                <meta property="og:description" content={data?.meta_description} />
                <meta property="og:image" content="https://kesrinandan.com/static/media/kesrinandan.4348074cbbe19e71283609c0c0969ee2.svg"></meta>
                <link rel="canonical" href={window?.location?.href} />
            </Helmet>
            <section className="about-banner brands-detail-banner">
                <div className="custom-text custom-text-about">
                    <p className='custom-title' style={{ textTransform: "capitalize" }}>
                        {slug}
                        {slug && <span className='color-dot'>.</span>}
                    </p>
                    <Breadcrumb paths={[
                        { label: 'Home', url: '/' },
                        // { label: data?.partner_details?.title || 'Leoni',  },
                        { label: `${slug}`, url: `/brand-detail/${slug}` }
                    ]} />
                </div>
            </section>

            <section className="brand-detail-section">

                {loading ? <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                /> : (
                    <>
                        <div>
                            <div className="brand-logo">
                                <h2>{data?.title}</h2>
                            </div>
                            <div className="brands-desc">
                                < div dangerouslySetInnerHTML={{ __html: `${data?.top_description}` }} />
                            </div>
                        </div>
                        {slug === 'livguard' ? (
                            <>
                                {renderProductsByCategoryId(data?.products_data)}
                            </>
                        ) : (
                            <div className='brand-products'>{
                                data?.products_data?.map((el) => (
                                    <div className="products-item" key={el?.id}>
                                        <Link to={`/product-detail/${el?.slug}`}>
                                            <div className="products-item-img">
                                                <img src={el?.image} alt="" />
                                            </div>
                                            <h5>{el?.title}</h5>
                                        </Link>
                                    </div>
                                ))
                            }</div>
                        )}
                        <div className="brands-desc brand-desc-2">
                            {loading ? <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D', '#FFCA4D',]}
                            /> : < div dangerouslySetInnerHTML={{ __html: `${data?.bottom_description}` }} />
                            }
                        </div>

                    </>
                )}


            </section>
            <CtaSection />

            <section className="faq-section">
                <div className="c-container">
                    <h2 className="description">If you have any questions, we will be very happy to answer them</h2>
                    <div className="faq-accordion-section">
                        <Accordion.Root className="AccordionRoot" type="single" collapsible>
                            {data?.dataAccordian?.map((el, index) => (
                                <Accordion.Item className="AccordionItem" key={index + 1} value={index + 1}>
                                    <AccordionTrigger>{el?.title}</AccordionTrigger>
                                    <AccordionContent>{el?.description}</AccordionContent>
                                </Accordion.Item>
                            ))}
                        </Accordion.Root>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BrandDetail