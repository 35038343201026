import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { AppContext } from '../../AppContext';

const SearchModal = () => {
  const [query, setQuery] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const { searchProducts } = useContext(AppContext);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (query) {
      await searchProducts(query); // Call the search function
      closeModal();
      navigate(`/search?q=${query}`); // Navigate to the search results page with query
    }
  };

  return (
    <div>
      <FaSearch 
        onClick={openModal} 
        style={{ cursor: 'pointer', fontSize: '22px', marginLeft: '10px' }} 
      />

      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal}
        appElement={document.getElementById('root')} 
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', 
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          },
        }}
      >
        <h2 style={{ marginBottom: '10px', position: 'relative' }}>
          Search
          <FaTimes 
            onClick={closeModal}
            style={{
              position: 'absolute',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              fontSize: '20px',
              color: '#333'
            }}
          />
        </h2>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter search query..."
            style={{
              padding: '10px',
              fontSize: '16px',
              width: '100%',
              marginBottom: '20px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          />
          <button 
            type="submit" 
            style={{ 
              padding: '10px 20px', 
              background: '#FFC107', 
              color: '#fff', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: 'pointer',
            }}>
            Search
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default SearchModal;
