import React, { useContext} from 'react';
import "./Footer.css";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { AppContext } from '../../AppContext';

// Images
import Logo from "../../assets/home/kesrinandan.svg";
import Location from "../../assets/footer/ffoter-loc.svg";
import Phone from "../../assets/footer/footer-phone.svg";
import Email from "../../assets/footer/footer-mail.svg";
import Icon1 from "../../assets/home/Group.svg";
import Icon2 from "../../assets/home/Group-1.svg";
import Icon3 from "../../assets/home/Group-2.svg";
import pdf from "../../assets/Disclaimer for Kesrinandan Ventures LLP.pdf";

const Footer = () => {
    const { menu, loading, error } = useContext(AppContext);

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scroll({
            top: elementPosition,
        });
    };

    const scrollTo = () => {
        window.scrollTo(0, 0);
    }



    const isLandingPage = () => {
        const landingPages = ['/landingpage/axitec', '/landingpage/livguard'];
        return landingPages.includes(window.location.pathname);
    };

    return (
        <footer>
            <div className="c-container">
                <div className="footer-top">
                    <div className="footer-top-content">
                        <div className="footer-logo">
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="footer-top-item">
                            <img src={Email} alt="email" />
                            <div className="item-info">
                                <p className="info-title">Email</p>
                                <Link to={"mailto:info@kesrinandan.com"} className="info-desc">info@kesrinandan.com</Link>
                            </div>
                        </div>
                        <div className="footer-top-item">
                            <img src={Phone} alt="phone" />
                            <div className="item-info">
                                <p className="info-title">Contact</p>
                                <Link to={"tel:+91-9828118888"} className="info-desc">+91-9828118888</Link>
                            </div>

                        </div>
                        <div className="footer-top-item">
                            <img src={Location} alt="location" />
                            <div className="item-info">
                                <p className="info-title">Location</p>
                                <p className="info-desc location-info-desc">Shop No. 33-34, Near Hanuman Temple, Talwandi, Kota, Rajasthan 324005</p>
                            </div>
                        </div>
                    </div>
                </div>

                {!isLandingPage() && (
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-col-2">
                            <p className='footer-desc'>
                                We, at Kesrinandan, can help you finance your rooftop solar power installation more easily thanks to our established relationships with banks and NBFCs.
                            </p>
                            <div className="footer-icons">
                                <Link to={"https://www.facebook.com/KesrinandanVentures.solar/"} target='_blank'>
                                    <img src={Icon1} alt="facebook" className="footer-icon" />
                                </Link>

                                <Link to={"https://www.linkedin.com/company/kesrinandan-ventures/"} target='_blank'>
                                    <img src={Icon2} alt="linkedin" className="footer-icon" />
                                </Link>

                                {/* <Link to={"https://twitter.com/KesrinandanV"}>
                                <img src={Icon4} alt="x" className="footer-icon" />
                            </Link> */}

                                <Link to={"https://www.instagram.com/kesrinandan_ventures/"} target='_blank'>
                                    <img src={Icon3} alt="instagram" className="footer-icon" />
                                </Link>

                            </div>
                        </div>

                        <div className="footer-col">
                            <h5 className='footer-items-title'>Menu</h5>
                            <ul className="footer-items">

                                <li className="footer-item" onClick={() => scrollTo()}>
                                    <Link to={"/blogs"}>
                                        Blogs
                                    </Link>
                                </li>
                                <li className="footer-item" onClick={() => scrollTo()}>
                                    <Link to={"/career"}>
                                        Career
                                    </Link>
                                </li>
                                <li className="footer-item">
                                    <Link to={"/faq"} onClick={() => scrollTo()}>
                                        FAQ'S
                                    </Link>
                                </li>

                            </ul>
                        </div>

                        <div className="footer-col">
                            <h5 className='footer-items-title'>Products</h5>
                            <ul className="footer-items">
                                {menu.category?.map((el) => {
                                    return (
                                        <li className="footer-item" key={el?.id} onClick={() => scrollTo()}>
                                            <Link to={`/product/${el.slug}`}>
                                                {el?.slug.replace(/-/g, ' ')}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="footer-col">
                            <h5 className='footer-items-title'>Sectors</h5>
                            <ul className="footer-items">
                                <li className="footer-item">
                                    <HashLink to={"/sectors#sector-3"} scroll={el => scrollWithOffset(el, 100)}>
                                        Government
                                    </HashLink>
                                </li>
                                <li className="footer-item">
                                    <HashLink to={"/sectors#sector-2"} scroll={el => scrollWithOffset(el, 100)}>
                                        Commercial
                                    </HashLink>
                                </li>
                                <li className="footer-item">
                                    <HashLink to={"/sectors#sector-1"} scroll={el => scrollWithOffset(el, 100)}>
                                        Industrial
                                    </HashLink>
                                </li>
                            </ul>
                        </div>


                        <div className="footer-col">
                            <h5 className='footer-items-title'>Quick Links</h5>
                            <ul className="footer-items">
                                <li className="footer-item">
                                    <Link to={"/ppa"} onClick={() => scrollTo()}>
                                        PPA
                                    </Link>
                                </li>

                                <li className="footer-item">
                                    <Link to={pdf} target='_blank'>
                                        Disclaimer
                                    </Link>
                                </li>

                                <li className="footer-item" onClick={() => scrollTo()}>
                                    <Link to={"/location"}>
                                        Our Locations
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div >
                )}

                <div className="footer-end">
                    <p className='footer-end-desc'>{`Copyright ©${new Date().getFullYear()} kesrinandan`} | Designed by <Link className='company-link' to={"https://itxitpro.com/"} >ITXITPRO.</Link></p>
                </div>
            </div>
        </footer >
    )
}

export default Footer