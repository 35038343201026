import React from 'react';
import './ThankYouPage.css'; 
import { Link } from 'react-router-dom';


const ThankYouPage = () => {
  return (
    <div className="thank-you-container">
      <h1 className="thank-you-heading">Thank You!</h1>
      <p className="thank-you-message">
        Your enquiry has been received.
        <br />
        We'll get back to you as soon as possible.
      </p>
      <Link to={"/"} className="to-home common-btn">Go back to Home</Link>
    </div>
  );
};

export default ThankYouPage;
